@import '~antd/dist/antd.less';

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.form-item-delete-btn {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.form-item-delete-btn:hover {
  color: #777;
}
.form-item-delete-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.plan-steps {
  margin-bottom: 24px;
}
.Login {
  background-image: url('./bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
.Login-Layout {
  background: none !important;
}
.Login-Header {
  background: none !important;
}
.Login-Text {
  color: #ea632e !important;
}

@primary-color: #ea632e;@layout-header-padding: 0 20px;@layout-header-background: #1a1c20;@layout-body-background: #f4f4f4;